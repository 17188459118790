import { ReactComponent as Header } from "./assets/header.svg";
import { useNavigate } from "react-router-dom";

export default function BrandHeader() {
  const navigate = useNavigate();

  return (
    <div className="grid grid-cols-1">
      <div className="place-self-stretch grid text-center place-items-center h-20 bg-red-400">
        {/* <div className="flex-wrap"> */}
        <Header
          className="h-14 w-28"
          onClick={() => {
            navigate("/");
          }}
        />
        {/* </div> */}
      </div>
    </div>
  );
}
