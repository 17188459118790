import { LinkIcon, ShareIcon } from "@heroicons/react/20/solid";
import { shareOnMobile } from "react-mobile-share";

function NotesSection({ notes }) {
  return notes ? (
    <div className="mt-2 flex justify-between">
      <p className="text-base font-small text-gray-900 line-clamp-5">{notes}</p>
    </div>
  ) : null;
}

function ButtonAction({ link }) {
  return link ? (
    <div className="mt-2 gap-4 flex items-center justify-center ">
      <div className="bg-gray-200 rounded-md">
        <LinkIcon
          stroke="blue"
          className="h-8 m-2"
          onClick={() => {
            window.open(link, "_blank", "noreferrer");
          }}
        />
      </div>

      <div className=" bg-gray-200 rounded-md">
        <ShareIcon
          stroke="blue"
          className="h-8 m-2"
          onClick={() => {
            shareOnMobile({
              title: "Share this list",
              text: "Check out this list",
              url: link,
            });
          }}
        />
      </div>
    </div>
  ) : null;
}

export default function ListsGrid({ currentLists, folderSlug }) {
  return (
    <div className="place-self-stretch">
      <div className="grid gap-4 auto-rows-max lg:grid-cols-4 med:grid-cols-2 p-10 min-h-max ">
        {currentLists
          .filter(
            (list_item) =>
              list_item.Name && list_item.category_slug[0] === folderSlug
          )

          .map((list_item) => (
            <div
              key={list_item.id}
              className=" group relative w-fill p-4 sm:p-6 text-gray-700 text-sm text-left font-medium rounded-md place-self-stretch border-green-300 border-2 shadow-md "
            >
              <div className="">
                <span className="line-clamp-2 text-xl font-semibold">
                  {list_item.Name}
                </span>
              </div>
              <div className="">
                <NotesSection notes={list_item.Notes} />
              </div>

              <div className="">
                <ButtonAction link={list_item.link} />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
