import { useEffect, useState } from "react";
import Counter from "./Counter";
export default function NoProfileSelected() {
  const [profiles, setProfiles] = useState([]);

  const refreshProfiles = async () => {
    setProfiles([]);

    try {
      const res = await fetch(`/.netlify/functions/profiles`);
      const lists = await res.json();
      setProfiles(lists);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    refreshProfiles();
  }, []);

  return (
    <div className="place-self-stretch">
      <div className="grid gap-4 auto-rows-max lg:grid-cols-4 med:grid-cols-2 min-h-max">
        {profiles.map((list_item) => (
          <div
            key={list_item.id}
            className="group relative w-fill p-10 sm:p-6 text-gray-700 text-sm text-center font-medium  place-self-stretch"
          >
            <div className="mt-2 gap-4 flex items-center justify-center">
              <span className="line-clamp-2 text-xl font-semibold capitalize">
                {list_item.slug}
              </span>
              <Counter countItems={list_item.count_list_items} />
            </div>

            <div className="">
              <button
                type="button"
                className="rounded-md border border-transparent bg-green-100 px-4 py-2 text-base font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <a href={`/${list_item.slug}`}>View Fav8</a>
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
