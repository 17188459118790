export default function CreateYourOwn() {
  return (
    <div className="flex flex-col items-center pt-2 pb-2 min-h-max">
      <button
        type="button"
        className="rounded-md border border-transparent bg-green-300 px-4 py-2 text-base font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        <a
          href="https://owy5b3doofd.typeform.com/to/rwnucGkx"
          target="_blank"
          rel="noreferrer"
        >
          Create Your Own List
        </a>
      </button>
    </div>
  );
}
