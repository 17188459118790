import ListsGrid from "./components/ListsGrid";
import NavBar from "./components/NavBar";
import "./App.css";
import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from "react-router-dom";
import CategoriesView from "./components/CategoriesView";
import Profile from "./components/Profile";
import CreateYourOwn from "./components/CreateYourOwn";
import FoldersView from "./components/FoldersView";
import NoProfileSelected from "./components/NoProfileSelected";
import BrandHeader from "./components/BrandHeader";
import LoadingOverlay from "./components/LoadingOverlay";

function App() {
  return (
    <Router>
      <div>
        <BrandHeader />

        {/* A <Routes> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Routes>
          <Route path="">
            <Route path="/" element={<NoProfileSelected />} />

            <Route
              path=":profileSlug/:categorySlug?/:folderSlug?/"
              element={<Home />}
            />
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

const Home = () => {
  let { profileSlug, categorySlug, folderSlug } = useParams();

  const [showLoader, setShowLoader] = useState(false);

  const [currentCategory, setCurrentCategory] = useState(null);
  const [currentFolder, setCurrentFolder] = useState(null);

  const [listings, setListings] = useState([]);

  const getListings = async () => {
    setShowLoader(true);

    try {
      const res = await fetch(`/.netlify/functions/lists?user=${profileSlug}`);
      const listings = await res.json();
      console.log(listings);
      setListings(listings);
    } catch (err) {
      console.error(err);
    }
    setShowLoader(false);
  };

  // fetch the current category via api with the slug
  const getCategoryviaSlug = async () => {
    if (categorySlug) {
      try {
        const res = await fetch(
          `/.netlify/functions/categories?slug=${categorySlug}`
        );
        const category = await res.json();
        setCurrentCategory(category.pop());
        // setShowLoader(false);
      } catch (err) {
        console.error(err);
      }
    } else {
      setCurrentCategory(null);
    }
  };

  // fetch the current folder via api with the slug
  const getFolderviaSlug = async () => {
    if (folderSlug) {
      try {
        const res = await fetch(
          `/.netlify/functions/folders?slug=${folderSlug}`
        );
        const folder = await res.json();
        setCurrentFolder(folder.pop());
      } catch (err) {
        console.error(err);
      }
    } else {
      setCurrentFolder(null);
    }
  };

  useEffect(() => {
    setShowLoader(true);
    getListings();
    getCategoryviaSlug();
    getFolderviaSlug();
  }, [profileSlug, categorySlug, folderSlug]);

  return (
    <div className="App bg-white ">
      <LoadingOverlay showLoader={showLoader} setShowLoader={setShowLoader} />
      <div className="pt-6 pb-6 sm:pb-24">
        <NavBar
          profileSlug={profileSlug}
          currentCategory={currentCategory}
          currentFolder={currentFolder}
        />
      </div>
      <div className="md:flex md:flex-wrap">
        <Profile />
        <hr />
        <CategoriesView
          profileLink={profileSlug}
          currentCategory={currentCategory}
          setShowLoader={setShowLoader}
          currentLists={listings}
        />
        <FoldersView
          parentUrl={`/${profileSlug}/${categorySlug}`}
          currentCategory={currentCategory}
          currentFolder={currentFolder}
          setShowLoader={setShowLoader}
          showLoader={showLoader}
          currentLists={listings}
        />
        <div className="md:w-2/3">
          <ListsGrid currentLists={listings} folderSlug={folderSlug} />
        </div>
        <hr />
        <CreateYourOwn />
      </div>
    </div>
  );
};

export default App;
