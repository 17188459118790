import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Counter from "./Counter";

export default function CategoriesView({
  profileLink,
  currentCategory,
  setShowLoader,
  currentLists,
}) {
  const [categories, setCategories] = useState([]);

  const fetchCategories = async () => {
    setShowLoader(true);

    try {
      const res = await fetch(`/.netlify/functions/categories`);
      const categories = await res.json();
      setCategories(categories);
    } catch (err) {
      console.error(err);
    }

    setShowLoader(false);
  };

  useEffect(() => {
    fetchCategories();
  }, [profileLink]);

  const navigate = useNavigate();
  return (
    <div className="md:w-2/3 p-6">
      <h2 className="text-xl font-bold">Category</h2>
      {categories.length > 0 ? (
        <div className="grid gap-2 grid-cols-2">
          {categories.map((category) => (
            <button
              key={category.Name}
              className={`place-self-stretch flex items-center justify-center h-16 w-full rounded-md text-center place-items-center ${
                category.slug === currentCategory?.slug ? "font-semibold" : ""
              }`}
              onClick={() => {
                navigate(`/${profileLink}/${category.slug}/`);
              }}
            >
              {category.Name}
              <Counter
                countItems={
                  currentLists.filter(
                    (x) => x.parent_category_slug[0] === category.slug
                  ).length
                }
              />
            </button>
          ))}
        </div>
      ) : (
        <div className="place-self-stretch grid h-16 w-full rounded-md text-center place-items-center">
          loading...
        </div>
      )}
    </div>
  );
}
