import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Counter from "./Counter";

export default function FoldersView({
  parentUrl,
  currentCategory,
  currentFolder,
  setShowLoader,
  showLoader,
  currentLists,
}) {
  const navigate = useNavigate();

  const [folders, setFolders] = useState([]);

  const fetchFolders = async () => {
    if (!currentCategory) {
      return;
    }

    setShowLoader(true);

    try {
      const res = await fetch(
        `/.netlify/functions/folders?parent_category=${currentCategory.slug}`
      );
      const folders = await res.json();
      setFolders(folders);
    } catch (err) {
      console.error(err);
    }
    setShowLoader(false);
  };

  useEffect(() => {
    setFolders([]);
    fetchFolders();
  }, [currentCategory]);

  return (
    <div className="md:w-2/3 pl-6">
      <h2 className="text-xl font-semibold">Folders</h2>
      {folders.length > 0 ? (
        <div className="grid gap-2 grid-cols-2">
          {folders.map((folder) => (
            <button
              key={folder.Name}
              className={`place-self-stretch flex items-center justify-cente h-16 w-full rounded-md text-center place-items-center ${
                folder.slug === currentFolder?.slug ? "font-semibold" : ""
              }`}
              onClick={() => {
                navigate(`${parentUrl}/${folder.slug}`);
              }}
            >
              {folder.Name}
              <Counter
                countItems={
                  currentLists.filter(
                    (list) => list.category_slug[0] === folder.slug
                  ).length
                }
              />
            </button>
          ))}
        </div>
      ) : (
        <div className="place-self-stretch grid h-16 w-full rounded-md text-center place-items-center">
          {showLoader
            ? "loading..."
            : "Please select a category to view the folders"}
        </div>
      )}
    </div>
  );
}
