import { ShareIcon } from "@heroicons/react/20/solid";
import { useLocation } from "react-router-dom";
import { shareOnMobile } from "react-mobile-share";

export default function Profile() {
  const history = useLocation();
  return (
    <div className="grid grid-cols-1">
      <div className="place-self-stretch grid  text-center place-items-center h-14">
        <div className="flex-wrap">
          <strong>{history.pathname}</strong>
        </div>
        <div className="">
          <ShareIcon
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-6 h-6 inline"
            onClick={() => {
              shareOnMobile({
                title: "Share this list",
                text: "Check out this list",
                url: window.location.href,
              });
            }}
          />
        </div>
      </div>
    </div>
  );
}
