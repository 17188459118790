import { useEffect, useState } from "react";

export default function NavBar({
  profileSlug,
  currentCategory,
  currentFolder,
}) {
  const [navItems, setNavBarItems] = useState([]);

  useEffect(() => {
    setNavBarItems([]);
    const items = [{ name: profileSlug, link: `/${profileSlug}` }];
    if (currentCategory) {
      items.push({
        name: currentCategory.Name,
        link: `/${profileSlug}/${currentCategory.slug}`,
      });

      if (currentFolder) {
        items.push({
          name: currentFolder.Name,
          link: `/${profileSlug}/${currentCategory.slug}/${currentFolder.slug}`,
        });
      }
    }
    setNavBarItems(items);
  }, [profileSlug, currentCategory, currentFolder]);

  return (
    <nav
      aria-label="Breadcrumb"
      className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"
    >
      <ol className="flex items-center space-x-4">
        {navItems.map((breadcrumb) => (
          <li key={breadcrumb.name}>
            <div className="flex items-center capitalize">
              <a
                href={breadcrumb.link}
                className="mr-4 text-sm font-medium text-gray-900"
              >
                {breadcrumb.name}
              </a>
              <svg
                viewBox="0 0 6 20"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                className="h-5 w-auto text-gray-300"
              >
                <path
                  d="M4.878 4.34H3.551L.27 16.532h1.327l3.281-12.19z"
                  fill="currentColor"
                />
              </svg>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}
